import { Table, TableColumn, Pagination, Button } from "element-ui";
import { omit } from "lodash";
import SchemaForm from "../SchemaForm";
import { concatTableData } from "@/plugins/common.js";

export default {
  name: "ComplexTable",
  components: {},
  props: ["columns", "requestApi", "schemaFormProps"],
  computed: {},
  data(da) {
    return {
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 5,
      total: 0,
    };
  },

  mounted() {
    this.getList({ formData: this?.schemaFormProps.initialValues });
  },
  methods: {
    getList(otherParams = {}) {
      const params = {
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        ...otherParams.formData,
      };
      this.loading = true;
      this.requestApi(params)
        .then(({ data }) => {
          if (data.isSuccess) {
            this.tableData = concatTableData(
              data.result.pageList,
              data.result.total,
              this.currentPage,
              this.pageSize
            );
            this.pageIndex = data.result.pageIndex;
            this.pageSize = data.result.pageSize;
            this.total = data.result.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSizeChange(nextPageSize) {
      this.pageSize = nextPageSize;
      this.getList();
    },

    onCurrentChange(nextCurrentPage) {
      this.currentPage = nextCurrentPage;
      this.getList();
    },

    onSubmit(formData) {
      this.getList({ formData });
    },
  },

  render() {
    return (
      <div>
        {this.schemaFormProps && (
          <SchemaForm
            dataSource={this.schemaFormProps.dataSource}
            loading={this.loading}
            initialValues={this.schemaFormProps.initialValues}
            onSubmit={this.onSubmit}
          />
        )}
        <div class="tableDiv">
          <Table
            data={this.tableData}
            style="width: 100%"
            loading={this.loading}
          >
            {this.columns.map((column, index) => (
              <TableColumn
                key={index}
                props={omit(column, "scopedSlots")}
                scopedSlots={column.scopedSlots}
              />
            ))}
          </Table>
          <Pagination
            class="tablePage"
            prevText={this.$t("MyFollowers_PreviousPage")}
            nextText={this.$t("MyFollowers_NexPage")}
            pageSizes={[5, 10, 20, 50, 100]}
            on-size-change={this.onSizeChange}
            on-current-change={this.onCurrentChange}
            currentPage={this.currentPage}
            pageSize={this.pageSize}
            layout="prev, pager, next, sizes"
            total={this.total}
          />
        </div>
      </div>
    );
  },
};
