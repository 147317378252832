import {
  Form,
  Button,
  FormItem,
  Input,
  Select,
  Option,
  DatePicker,
} from "element-ui";
import "./index.less";

export const fieldTypeMap = {
  textInput: "textInput",
  select: "select",
  datePicker: "datePicker",
};
const fieldItemMap = {
  [fieldTypeMap.textInput]: Input,
  [fieldTypeMap.select]: Select,
  [fieldTypeMap.datePicker]: DatePicker,
};

export default {
  name: "SchemaForm",
  props: ["dataSource", "initialValues", "loading"],
  data(self) {
    let initialValues;
    if (self.initialValues) {
      initialValues = self.initialValues;
    }
    return {
      formData: initialValues,
    };
  },

  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.$emit("reset");
      this.$emit("submit", this.formData)
    },
  },

  render() {
    return (
      <div class="tableSearch">
        <Form labelPosition="top" ref="form" props={{ model: this.formData }}>
          {this.dataSource.map((itemData, index) => {
            const InputComponent = fieldItemMap[itemData.fieldType];

            let OptionsElement;
            if (itemData.fieldType === fieldTypeMap.select) {
              OptionsElement = itemData.fieldProps.options.map(
                (selectItem, index) => (
                  <Option
                    key={index}
                    value={selectItem.value}
                    label={selectItem.label}
                  />
                )
              );
            }

            let datePickerInitProps;

            if (itemData.fieldType === fieldTypeMap.datePicker) {
              datePickerInitProps = {
                rangeSeparator: "—",
                valueFormat: "yyyy-MM-dd",
                startPlaceholder: this.$t("MyFollowers_ChooseStartTime"),
                endPlaceholder: this.$t("MyFollowers_ChooseEndTime"),
              };
            }

            return (
              <FormItem key={index} prop={itemData.name} label={itemData.label}>
                <InputComponent
                  prop={itemData.name}
                  v-model={this.formData[itemData.name]}
                  {...{
                    props: { ...datePickerInitProps },
                  }}
                >
                  {OptionsElement}
                </InputComponent>
              </FormItem>
            );
          })}
          <FormItem class="noLabel">
            <Button
              class="serchFormBtn loading-button"
              loading={this.loading}
              onClick={() => this.$emit("submit", this.formData)}
            >
              {this.$t("MyFollowers_Search")}
              <svg-icon icon-class="SearchIcon"></svg-icon>
            </Button>
          </FormItem>
          <FormItem class="noLabel">
            <Button
              class="resetFormBtn loading-button"
              loading={this.loading}
              onClick={this.reset}
            >
              {this.$t("MyFollowers_Reset")}
              <svg-icon icon-class="ResetIcon"></svg-icon>
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  },
};
