export default {
  name: "pageTitle",
  components: {},
  props: ["value"],

  render() {
    return (
      <div class="pageTitle">
        {this.value}
      </div>
    );
  },
};
